import React from 'react';
import './MyCv.css';

const MyCv = () => {
    return (
        <div className="lebenslauf-container">     
            <h1>Lebenslauf</h1>
            <div>
                <h3>BERUFSERFAHRUNG</h3>
            </div>
            <div className='my-cv'>
            <div className='arbeit'>
                <div className='jahre'>
                    <p>08.2024 - Heute</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Tutor Programmierung</p>
                        <p>Universität Douala</p>
                        <ul>
                            <li>Unterricht & Übungen Java (erstes Jahr)</li>
                            <li>Übungen Mobile design (drittes Jahr)</li>
                            <li>Java (Klassen, Vererbung, Polymorphismus usw...)</li>
                        </ul>
                    </div>
                </div>
            </div>  

            <div className='arbeit'>
                <div className='jahre'>
                    <p>11.2023 - 03.2024</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Werkstudent B.I</p>
                        <p>PRODATO GmbH, Nürnberg</p>
                        <ul>
                            <li>Entwicklung von .NET Core-Desktop App</li>
                            <li>Erstellen ein interaktives BI-Dasboard für das Management</li>
                            <li>Entwicklung von .NET Core-Desktop App</li>
                        </ul>
                    </div>
                </div>
            </div>  

            <div className='arbeit'>
                <div className='jahre'>
                    <p>10.2022 - 10.2023</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Werkstudent in Industry 4.0</p>
                        <p>Setago GmbH, Nürnberg</p>
                        <ul>
                            <li>Algorithmenentwicklung (Python & Go)</li>
                            <li>Datentransfer und Datensicherheit mit einem MQTT-Protokol</li>
                            <li>Durchführung von manuelle und automatische Testen</li>
                        </ul>
                    </div>
                </div>
            </div> 

            <div className='arbeit'>
                <div className='jahre'>
                    <p>04.2022 - 06.2022</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Werkstudent EnergieTechnik</p>
                        <p>Heitec AG, Erlangen</p>
                        <ul>
                            <li>Auswertung und Visualisierung von Simulationsergebnissen</li>
                            <li>Visualisierung von Energieflüssen in Energiesystemen</li>
                            <li>Entwicklung mit 3D-Konstruktionstools</li>
                        </ul>
                    </div>
                </div>
            </div>  

            <div className='arbeit'>
                <div className='jahre'>
                    <p>04.2021 - 08.2021</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Praktikum im Bereich Chassis</p>
                        <p>Schaeffler Technologies AG</p>
                        <ul>
                            <li>Algorithmenentwicklung</li>
                            <li>Machbarkeitsstudien für technische Module und KI-basierten Vorhersagemodelle</li>
                            <li>Aufbau von Datenbanken für Ausfallanalysen, Requirements oder Komponenten</li>
                            <li>Entwicklung von Tools oder Methoden für Tracking, Testmanagement </li>
                        </ul>
                    </div>
                </div>
            </div>    
                <div>
                    <h3>BILDUNGSWEG</h3>
                </div>

            <div className='arbeit'>
                
                <div className='jahre'>
                    <p>2009 - 2012</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Physik (Bachelor)</p>
                        <p>Universität Douala</p>
                        <ul>
                            <li>Mechanik und klassische Physik</li>
                            <li>Grundlagen der Elektrizität, wie Coulomb-Gesetz und Kirchhoffsche Regeln</li>
                            <li>Quantenmechanik und moderne Physik</li>
                        </ul>
                    </div>
                </div>
            </div>  

            <div className='arbeit'>
                <div className='jahre'>
                    <p>2012 - 2013</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Deutschkurs</p>
                        <p>Goethe Institut Douala</p>
                        <ul>
                           
                        </ul>
                    </div>
                </div>
            </div>  

            <div className='arbeit'>
                <div className='jahre'>
                    <p>2013 - 2014</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Deutschkurs</p>
                        <p>Goethe Institut Erlangen</p>
                        <ul>
                           
                        </ul>
                    </div>
                </div>
            </div> 

            <div className='arbeit'>
                <div className='jahre'>
                    <p>2014 - 2017</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>E-Commerce</p>
                        <p>THWS (Würzburg)</p>
                        <ul>
                            <li>Online Marketing</li>
                            <li>Web-Programmierung</li>
                            <li>Oberflächengestaltung und Benutzerfreundlichkeit im Web</li>
                        </ul>
                    </div>
                </div>
            </div>  

            <div className='arbeit'>
                <div className='jahre'>
                    <p>2017 - 2023</p>
                </div>
            
                <div className='beschreibung'>
                    <div className='stelle'>
                        <p>Informatik (Bachelor)</p>
                        <p>THWS (Würzburg)</p>
                        <ul>
                            <li>Betriebssysteme</li>
                            <li>Maschinentheorie, Formale Sprachen, Compilerbau und Berechenbarkei</li>
                            <li><b>Vertiefungs:</b> Mobile Lösungen & Design (Entwurf und Entwicklung von nativen App)</li>
                        </ul>
                    </div>
                </div>
            </div>   
                <div>
                    <h3>KENNTNISSE & SKILLS</h3>
                </div>
                    
                <div className='arbeit'>
                    
                    <div className='progress'>
                        <div>
                            <label className='texte-beschreibung'for= "go">go  </label>
                            <progress id='go' max="100" value={20}>20%</progress>
                        </div>
                       
                        <div>
                            <label className='texte-beschreibung'for= "go">Python </label>
                            <progress id='go' max="100" value={50}>20%</progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "go">HTLM & CSS </label>
                            <progress id='go' max="100" value={60}></progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "Github & Gitlab">Github & Gitlab </label>
                            <progress id='go' max="100" value={70}></progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "go">Kanban & Scrum </label>
                            <progress id='go' max="100" value={75}></progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "go">SQLite & Nosql </label>
                            <progress id='go' max="100" value={80}></progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "go">MQTT </label>
                            <progress id='go' max="100" value={87}></progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "go">C# </label>
                            <progress id='go' max="100" value={80}></progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "go"> java</label>
                            <progress id='go' max="100" value={85}></progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "go">Firebase </label>
                            <progress id='go' max="100" value={85}></progress> 
                        </div>

                        <div>
                            <label className='texte-beschreibung'for= "go"> Systemintegration</label>
                            <progress id='go' max="100" value={70}></progress> 
                        </div>
                    
                    </div>
                </div>
        </div>
            </div>

           
    );
};

export default MyCv;
