import React from 'react';
import MyComponent from '../components/MyComponent';
import FooterContact from '../components/FooterContact';
import MyCv from '../components/MyCv';

const CvPage = () => {
    return (
       <div>
        <MyComponent/>
        <MyCv/>
        <FooterContact/>
       </div>
    );
};

export default CvPage;
