
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebase = {
            apiKey: "AIzaSyDMIccES7aiPWFw9tVHln_FVkGKUkln22M",
            authDomain: "cvmessages-cf705.firebaseapp.com",
            projectId: "cvmessages-cf705",
            storageBucket: "cvmessages-cf705.firebasestorage.app",
            messagingSenderId: "71885009836",
            appId: "1:71885009836:web:8d52f4a1b6d7cb83080009",
            measurementId: "G-8HEL9DP872"
};

const app = initializeApp(firebase);

// Initialiser Firestore
const db = getFirestore(app);
export { db, collection, addDoc };

export default firebase;