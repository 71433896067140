import React, { useState } from 'react';
import { MdMenu } from 'react-icons/md';
import './MyComponent.css';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';

const MyComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null); // État pour gérer l'ouverture/fermeture du menu
  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // Définit l'élément d'ancrage pour ouvrir le menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Réinitialise l'élément d'ancrage pour fermer le menu
  };

  const handleCvItemClick = (path) => {
    navigate('/Cv'); // Navigation vers une page donnée
    handleClose(); // Ferme le menu après navigation
  };

  const handleProjectItemClick = (path) => {
    navigate('/Projets'); // Navigation vers une page donnée
    handleClose(); // Ferme le menu après navigation
  };

  const handleContactItemClick = (path) => {
    navigate('/Contact'); // Navigation vers une page donnée
    handleClose(); // Ferme le menu après navigation
  };

  const handleHomePage = () => {
    navigate('/'); // Naviguer vers la page d'accueil
  };

  return (
    <div className="container">
      <button className="circle" onClick={handleHomePage} />

      <span className="boldText">Armand Njomo</span>

      <div className='profilName'>
        <button
          className="iconButton"
          onClick={handleMenuClick}
          aria-controls={anchorEl ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
        >
          <MdMenu size={30} color="black" />
        </button>

        <Menu
          id="fade-menu"
          anchorEl={anchorEl} // Définit l'élément d'ancrage
          open={Boolean(anchorEl)} // Si anchorEl est défini, le menu est ouvert
          onClose={handleClose} // Ferme le menu lorsque l'utilisateur clique en dehors
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          TransitionComponent={Fade} // Ajoute une transition d'ouverture/fermeture
        >
          <MenuItem onClick={() => handleCvItemClick('/Cv')}>Cv</MenuItem>
          <MenuItem onClick={() => handleProjectItemClick('/Projets')}>Projekte</MenuItem>
          <MenuItem onClick={() => handleContactItemClick('/Contact')}>Kontakt</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default MyComponent;

