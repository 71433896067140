import React from 'react';
import MyComponent from '../components/MyComponent';
import AboutMyself from '../components/AboutMyself';
import FooterContact from '../components/FooterContact';

const HomePage = () => {
  return ( 
      <div>
        <MyComponent/>
        <AboutMyself/>
        <FooterContact />
      </div>
    
  );
};

export default HomePage;
