import React, { useState } from 'react';
import './Kontakt.css';
import Alert from '@mui/material/Alert';
import { db, collection, addDoc } from '../firebase/firebase';  // Importation de Firestore
import { Timestamp } from 'firebase/firestore';


const Kontakt = () => {
    const [formData, setFormData] = useState({
        vorname: '',
        nachname: '',
        email: '',
        bettref: '',
        message: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const validateForm = () => {
        const { vorname, nachname, email, bettref, message } = formData;
        if (!vorname || !nachname || !email || !bettref || !message) {
            setAlert({ message: 'Bitte füllen Sie alle Felder aus.', type: 'error' });
            setTimeout(() => {
                setAlert({ message: '', type: '' });
            }, 2500);

            return false;
        }
        // ici on valide l'email adresse
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setAlert({ message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.', type: 'error' });
            setTimeout(() => {
                setAlert({ message: '', type: '' });
            }, 2500);

            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setIsSubmitting(true);
        
        try {
            // Ajouter le message à Firestore
            await addDoc(collection(db, 'messages'), {
                vorname: formData.vorname,
                nachname: formData.nachname,
                email: formData.email,
                bettref: formData.bettref,
                message: formData.message,
                timestamp: Timestamp.now(),
            });
            setAlert({ message: 'Nachricht erfolgreich gesendet!', type: 'success' });
            setTimeout(() => {
                setAlert({ message: '', type: '' });
            }, 2500);

            // Réinitialiser le formulaire
            setFormData({ vorname: '', nachname: '', email: '', bettref: '', message: '' });
        } catch (error) {
            setAlert({ message: `Fehler: ${error.message}`, type: 'error' });
            setTimeout(() => {
                setAlert({ message: '', type: '' });
            }, 2500);
        } finally {
            setIsSubmitting(false);
        }
};

    return (
        <div className="contact-container">
            <div className="contact-details">
                <div>
                    <h1 className="title-contact">Kontakt</h1>
                    <p className="texte-contact">Ich freue mich, von Ihnen zu hören</p>
                </div>

                <div>
                    <h3 className="title-telefon">Telefon</h3>
                    <p className="texte-contact">+49 (0) 176 5981 2080</p>
                </div>

                <div>
                    <h3 className="title-email">E-Mail Adresse</h3>
                    <p className="texte-contact">armand.nguesseu@gmail.com</p>
                </div>
            </div>

            <div className="send-message-container">
                <div className="name-container">
                    <div className="input-first-containers">
                        <label htmlFor="vorname">Vorname</label>
                        <input
                            type="text"
                            id="vorname"
                            className="custom-input"
                            value={formData.vorname}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-first-containers">
                        <label htmlFor="nachname">Nachname</label>
                        <input
                            type="text"
                            id="nachname"
                            className="custom-input"
                            value={formData.nachname}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="name-subject-container">
                    <div className="input-second-containers">
                        <label htmlFor="email">E-Mail-Adresse*</label>
                        <input
                            type="email"
                            id="email"
                            className="custom-input"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-second-containers">
                        <label htmlFor="bettref">Bettref</label>
                        <input
                            type="text"
                            id="bettref"
                            className="custom-input"
                            value={formData.bettref}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="name-subject-container-message">
                    <div className="input-third-containers">
                        <label htmlFor="message">Nachricht</label>
                        <textarea
                            id="message"
                            name="message"
                            className="custom-input"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                            <button
                                className="circleButton contact"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Absenden...' : 'Absenden'}
                            </button>
                    </div>
                </div>
                <div>
                        {alert.message && (
                        <div
                            style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 100, 
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Alert
                                severity={alert.type}
                                style={{
                                    padding: '20px',
                                    fontSize: '14px',
                                    width: '60%', 
                                    maxWidth: '500px',
                                    textAlign: 'center', 
                                }}
                            >
                                {alert.message}
                            </Alert>
                        </div>
                    )}

                </div>
            </div>
            
        </div>
    );
};

export default Kontakt;
