import React from 'react';
import './FooterContact.css'; // Importer le fichier CSS

const FooterContact = () => {
  return (
    <div className="footer-container">
      <h2 className="footer-title">Kontakt</h2>

      <div className="footer-info-container">
        <span className="footer-label">Telefon:</span>
        <span className="footer-text">+49 176 5981 2080</span>
      </div>

      <div className="footer-info-container">
        <span className="footer-label">Email:</span>
        <span className="footer-text">armand.nguesseu@gmail.com</span>
      </div>

      <div className="footer-links-container">
        <button className="footer-link-button">Cookies</button>
        <button className="footer-link-button">Impressum</button>
        <button className="footer-link-button">Datenschutz</button>
      </div>

      <div className="footer-copyright">
        <span>© 2024 Armand Njomo</span>
      </div>
    </div>
  );
};

export default FooterContact;
